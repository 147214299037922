body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#menu.navbar-default {
  background-color: #080808 !important;
}
#menu.navbar-default .navbar-nav > li > a {
  color: rgb(216, 216, 216);}

  #menu.navbar-default .navbar-nav > li > a:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, #350300 0%, #fd4839 100%);
    content: "";
  }
  .intro {
    display: table;
    width: 100%;

    padding: 0;
    background-color: #080808;
  }
  .header{
    min-height: 100%;
  }
  #footer{
    background-color: #101010 !important;
  }
  #services {
    padding: 100px 0;
    background: #080808;
    color: #cecece;
}

@media (min-width: 992px){
  #products{
    display: inline-block;
    width:50% !important;
    padding:50px !important;
  }
}
@media (max-width: 992px){
  #products{
    padding:50px !important;
    display: inline-block;
    width:100% !important;
  }
}


.overlay {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.waves-demo {
  
  font-size: 45px;
  font-weight: bold;
  transition: all 200ms;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 60%;
  display: grid;
  place-items: center;
  z-index: 1;
  cursor: pointer;
  position: relative;
  padding: 20px;
}

/*Waves*/
.waves {
  color: blue;

  position: relative;
}
.waves:after{
  content: attr(data-word);
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  animation: waves 2s ease-in-out infinite forwards;
}
@keyframes waves{
  0%, 100% {
      clip-path: polygon(0 66%, 11% 59%, 18% 51%, 26% 46%, 35% 41%, 48% 44%, 55% 54%, 63% 63%, 76% 60%, 82% 50%, 92% 48%, 100% 53%, 100% 100%, 0% 100%);
  }
  50% {
      clip-path: polygon(0 66%, 8% 74%, 17% 77%, 28% 70%, 35% 57%, 48% 44%, 56% 39%, 69% 41%, 75% 47%, 84% 60%, 92% 61%, 100% 53%, 100% 100%, 0% 100%);
  }
}

.navbar-default .navbar-nav > .active > a:after, .navbar-default .navbar-nav > .active > a:hover:after, .navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #350300 0%, #fd4839 100%)!important;
}