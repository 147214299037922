@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}
.snip1336 {
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  filter: drop-shadow(0 0 0.25rem #a3a3a3);
  border-radius:20px;
  min-width: 230px;
  max-width: 240px;
  width: 100%;
  color: #ffffff;
  text-align: left;
  display: inline-grid;
  line-height: 1.4em;
  background-color: #141414;
}
.snip1336 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.snip1336 img {
  max-width: 100%;
  vertical-align: top;
  opacity: 0.85;
}
.snip1336 figcaption {
  width: 100%;
  
  background-color: #fefefe;
  padding: 25px;
  position: relative;
}
.snip1336 figcaption:before {
  position: absolute;
  content: '';
  bottom: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 0 0 400px;
  border-color: transparent transparent transparent #fefefe;
}
.snip1336 figcaption a {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 0.7em;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  opacity: 0.65;
  width: 47%;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
}
.snip1336 figcaption a:hover {
  opacity: 1;
}
.snip1336 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 100%;
  left: 25px;
  z-index: 1;
  max-width: 90px;
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.snip1336 .follow {
  margin-right: 4%;
  border-color: #2980b9;
  color: #2980b9;
}
.snip1336 h2 {
  margin: 0 0 5px;
  font-weight: 300;
}
.snip1336 h2 span {
  display: block;
  font-size: 0.5em;
  color: #2980b9;
}
.snip1336 p {
  margin: 0 0 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
  opacity: 0.8;
}
#linkicon{
  color: #0e0e0e;
    width: 20px;
    /* margin-left: 20%; */
    font-size: 22px;
    cursor: pointer;
    top:-5px;
    margin-top: 2px;
    /* display: block; */


}
#linkicon:hover{
  color:#d95622
}
.slick-prev:before,
.slick-next:before {
  color: rgb(171, 46, 7);
}
.slick-slide{
  height: auto !important;
}
#card4{
  background: linear-gradient(#e23c2ee0, #761912);
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 20px;
  margin-right:20px;
}

#btn2{
  padding: 8px;
  border-radius: 10px;
  margin: 0;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 12px;
  background: whitesmoke;
  color: black;
}
@media only screen and (min-width: 1000px) {
#info{
  margin-left:40px;
  margin-top:160px;
}
}
#ully{
  margin-bottom: 6px;
}